import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Website Support Scope" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE SUPPORT <br/>
<span> SCOPE</span></h2>
                                <p></p>
                            
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span></span></div>
                        <div className="container main-about">
                          


                            
                        
                        <p >
                            <h6 style={{fontSize: '13px'}}>
                                The scope of website support provided by PragICTS encompasses a comprehensive range of services designed to ensure the optimal performance and security of your website. The details are as follows:
                            </h6>
                        </p><br/>

                        <p >
                        <div className="time-line-icon">
                                    <i className="fal pragicts-content-icon-new" style={{innerWidth:'20px'}}></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (1) Content Updates
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                                For websites lacking content management functionality, PragICTS will take charge of creating, optimizing, and updating all website content, including text, images, and multimedia elements. This ensures that the website remains current and relevant, enhancing user engagement and search engine visibility. Clients can submit requests for updates, and PragICTS will implement these changes promptly.
                            </h6>
                        </p><br/>
                          <p > <div className="time-line-icon">
                                    <i className="fal pragicts-backup-icon-new"></i>
                            </div>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>(2) Backup</h3> <h6 style={{fontSize: '13px',textAlign:'justify'}}>Regular backups are critical for maintaining data integrity. PragICTS will conduct routine backups of the website to safeguard against data loss. Specifically, a backup will be taken after each content update, providing an added layer of security and ensuring that previous versions can be restored if necessary.</h6>
                          </p><br/>
                          <p > <div className="time-line-icon">
                                    <i className="fal pragicts-seo-icon-new"></i>
                            </div>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>(3) Management of Meta Definitions</h3> <h6 style={{fontSize: '13px',textAlign:'justify'}}>Effective on-site SEO is essential for improving search engine rankings. PragICTS will manage all aspects of meta definitions, including titles, descriptions, and keywords. Additionally, they will oversee the registration of the website with Google Business, configure Google Analytics for traffic tracking, and set up Open Graph tags to enhance social media sharing. This holistic approach ensures that the website is optimized for both search engines and social platforms.</h6>
                          </p><br/>
                          <p > <div className="time-line-icon">
                                    <i className="fal pragicts-performance-icon-new"></i>
                            </div>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>(4) Performance Upkeep</h3> <h6 style={{fontSize: '13px',textAlign:'justify'}}>To enhance user experience and loading times, PragICTS will optimize the website for performance. This includes the management of a Content Delivery Network (CDN), which distributes website content across multiple servers globally, reducing latency and improving access speed for users, regardless of their location.</h6>
                          </p><br/>

                          <p > <div className="time-line-icon">
                                    <i className="fal pragicts-bug-icon-new"></i>
                            </div>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}> (5) Bug Fixes and Cosmetic Adjustments</h3> <h6 style={{fontSize: '13px',textAlign:'justify'}}>PragICTS is committed to maintaining the website’s functionality and aesthetics. They will address any bug fixes and make non-structural cosmetic adjustments as needed. This ensures that the website operates smoothly and remains visually appealing to users, thereby enhancing overall user satisfaction.
                          </h6>
                          </p><br/>

                          <p > <div className="time-line-icon">
                                    <i className="fal pragicts-cyber-icon-new"></i>
                            </div>< h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>(6) Cybersecurity Measures</h3> <h6 style={{fontSize: '13px',textAlign:'justify'}}>In today’s digital landscape, protecting the website from cyber threats is paramount. PragICTS will implement robust security measures to prevent cyber attacks, including regular security audits and updates. In the unfortunate event of a security breach, they will ensure full recovery, restoring the website to its original state and securing any compromised data.

                          </h6>
                          </p><br/>
                          <p ><h6 style={{fontSize: '13px',textAlign:'justify'}}>Overall, PragICTS’s comprehensive support services are designed to not only maintain the website but also enhance its performance, security, and user engagement, allowing clients to focus on their core business activities with peace of mind.</h6></p>
<div>










</div> 





















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
